import { Injectable } from '@angular/core';
import { createClient, Entry, ContentfulClientApi } from 'contentful';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root',
})

export class ContentfulService {
  private client: ContentfulClientApi;
  constructor(
  ) {
    this.client = createClient({
      space: environment.contentful.space,
      accessToken: environment.contentful.accessToken
    });
  }

  async getHomePage(pageId: any, include: any): Promise<Entry<any>> {
    const res = await this.client.getEntries(Object.assign({
      content_type: 'homePage'
    }, { 'sys.id': pageId, include }));
    return res.items[0];
  }

  async getAboutPage(pageId: any, include: any): Promise<Entry<any>> {
    const res = await this.client.getEntries(Object.assign({
      content_type: 'aboutPage'
    }, { 'sys.id': pageId, include }));
    return res.items[0];
  }

  async getCommercialPage(pageId: any, include: any): Promise<Entry<any>> {
    const res = await this.client.getEntries(Object.assign({
      content_type: 'commercialPage'
    }, { 'sys.id': pageId, include }));
    return res.items[0];
  }

  async getResidentialPage(pageId: any, include: any): Promise<Entry<any>> {
    const res = await this.client.getEntries(Object.assign({
      content_type: 'residentialPage'
    }, { 'sys.id': pageId, include }));
    return res.items[0];
  }

  async getManagementPage(pageId: any, include: any): Promise<Entry<any>> {
    const res = await this.client.getEntries(Object.assign({
      content_type: 'managementPage'
    }, { 'sys.id': pageId, include }));
    return res.items[0];
  }

    async getWholesalePage(pageId: any, include: any): Promise<Entry<any>> {
    const res = await this.client.getEntries(Object.assign({
      content_type: 'wholesalePage'
    }, { 'sys.id': pageId, include }));
    return res.items[0];
  }

  async getInfo(pageId: string, include: string): Promise<Entry<any>> {
    const res = await this.client.getEntries(Object.assign({
      content_type: 'companyInfo'
    }, { 'sys.id': pageId, include }));
    return res.items[0];
  }

    async getContact(): Promise<Entry<any>> {
      const res = await this.client.getEntries(Object.assign({
        content_type: 'contactForm'
      }, ));
      return res.items[0];
    }
}
