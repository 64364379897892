<div id="ctaContainer" class="mt-3 mt-md-5 mb-5 w-100 position-relative text-center">
  <div class="position-relative w-100 ps-3 pe-3">
    <button type="button" id="inlineCta" class="inlineCta" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
      {{data?.fields.label}}
    </button>
    <div id="line" class="line"></div>
    </div>
  <div id="collapseExample" [ngbCollapse]="isCollapsed" [attr.aria-expanded]="!isCollapsed" class="ps-3 pe-3 border-black-3 border-top-fade">
    <div class="row g-0 p-5 row-one">
      <div class="col-md-5 text-start pt-lg-5 ps-lg-3">
          <h5>
            {{data?.fields.headline}}
            </h5>
            <markdown [data]="data?.fields.copy"></markdown>
        </div>
        <div id="contactForm" class="col-md-7 pt-5 pe-lg-3">
          <form id="contact-form" [formGroup]="infoForm" (ngSubmit)="sendMail(name, email, phone, message)" #form="ngForm">
            <div class="row mb-3 g-3">
              <div class="col-lg-12">
                <input type="text" id="nameInp" class="form-control" formControlName="name" placeholder="Name">
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger p-1">

                  <p *ngIf="name.errors.required" class="mb-0">
                    Name is required.
                  </p>
                  <p *ngIf="name.errors.minlength" class="mb-0">
                    Name must be at least 3 characters long.
                  </p>
                </div>
              </div>
            </div>
            <div class="row mb-3 g-3">
              <div class="col-lg-6">
                <input type="tel" class="form-control" id="tel" formControlName="phone" placeholder="Phone">
                <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="alert alert-danger p-1">

                  <p *ngIf="message.errors.required" class="mb-0">
                    A phone number is required.
                  </p>

                </div>
              </div>
              <div class="col-lg-6">
                <input type="text" id="mailInp" class="form-control" formControlName="email" placeholder="Email">
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger p-1">

                  <p *ngIf="email.errors.required" class="mb-0">
                    Email is required.
                  </p>
                  <p *ngIf="email.errors.email" class="mb-0">
                    Email is incorrect.
                  </p>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <textarea class="form-control" id="textarea" formControlName="message" rows="5" placeholder="Message"></textarea>

              <div *ngIf="message.invalid && (message.dirty || message.touched)" class="alert alert-danger p-1">

                <p *ngIf="message.errors.required" class="mb-0">
                  Please provide additional information.
                </p>

              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button id="formButton" type="submit" class="btn btn-primary" [disabled]="form.invalid" data-sitekey="6LdpogQoAAAAACAmL3XFHI5vU55vehxqPnnU-qlU" 
        data-callback='onSubmit' 
        data-action='submit'>Submit</button>
            </div>
            <div class="d-flex flex-column">
              <div *ngIf="successMessage" class="alert alert-success d-flex justify-content-center align-items-center mt-3">
                <div markdown [data]="data?.fields.successMessage">
                </div>
              </div>
              <div *ngIf="errorMessage" id="hideMe" class="alert alert-warning d-flex justify-content-center align-items-center mt-3">
                <div markdown [data]="data?.fields.errorMessage">
                </div>
              </div>
            </div>
          </form>
          </div>
      </div>

  </div>
</div>
