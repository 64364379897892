import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Iinfo } from '../interface/info.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SendEmailServiceService {
   url = environment.urlToBackend;

  constructor(private http: HttpClient) { }

  sendEmail(obj): Observable<Iinfo> {
  return this.http.post<Iinfo>(`${this.url}`, obj)
}

}
