import {
  Component,
  Inject,
  OnInit
} from "@angular/core";
import {
  DOCUMENT,
  Location
} from '@angular/common';

import { Router, NavigationEnd } from '@angular/router';
import { ContentfulService } from './services/contentful.service';
import { Entry } from 'contentful';
import { Meta } from '@angular/platform-browser';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  navElement!: HTMLElement;
  offCanvas!: HTMLElement;
  navButton!: HTMLElement
  title = "Pressberg Properties";
  currentRoute!: string;
  isHome!: boolean;
  data!: Entry<any>;
  hostName!: string;
  currentUrl!: string;
  isProd!: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(Location) private readonly location: Location,
    public router: Router,
    private contentfulService: ContentfulService,
    private meta: Meta
  ) {
    this.isProd = false;
    this.hostName = this.document.location.hostname;
    if (this.hostName === 'www.pressbergproperties.com') {
      this.meta.addTag({ name: 'robots', content: "index, follow" });
      this.isProd = true;
    }
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = this.location.path();
          this.loadCanonical(this.currentUrl);
          this.currentRoute = event.url;
          if (this.currentRoute === '/') {
            this.isHome = false;
          } else {
            this.isHome = true;
          }
        }
      });
    }

    ngOnInit(): void {
      this.hostName = this.document.location.hostname;
      if(this.hostName === 'www.pressbergproperties.com') {
      this.meta.addTag({ name: 'robots', content: "index, follow" });
    };
    this.navElement = null;
    this.navButton = null;
    this.offCanvas = null;
    const pageId = '6xjaSZjUBzdKBarCmLp3lg';
    const include = '5';
    this.contentfulService.getInfo(pageId, include)
      .then((data) => {
        this.data = data;
      });
    setTimeout(() => { }, 500);
  }

  ngAfterViewInit() {
    this.navElement = <HTMLElement>this.document.getElementById("navbarCenter");
    this.offCanvas = <HTMLElement>this.document.getElementById("navLink");
    this.navButton = <HTMLElement>this.document.getElementById("navButton");
    this.listenLinks();
  }

  public loadCanonical(url: string) {
    var elementExists = this.document.getElementById("canonicalLink");
    if (elementExists) {
      elementExists.remove();
    }
    const head = <HTMLDivElement>this.document.head;
    const link = this.document.createElement('link');
    link.href = url;
    link.rel = 'canonical';
    link.id = 'canonicalLink';
    head.appendChild(link);
  }
  listenLinks() {
    const burger = Array.from(this.document.querySelectorAll('.burger'));
    burger.forEach((l) => {
      l.addEventListener('click', () => {
        let x = this.navButton.getAttribute("aria-expanded");
        if (x == "true") {
          x = "false"
        } else {
          x = "true"
        }
        this.navButton.setAttribute("aria-expanded", x);
        this.navElement.classList.toggle('show');
      })
    });

    const navLinks = Array.from(this.document.querySelectorAll('.navLink'));
    navLinks.forEach((l) => {
      l.addEventListener('click', () => {
        let x = this.navButton.getAttribute("aria-expanded");
        if (x == "true") {
          x = "false"
        } else {
          x = "true"
        }
        this.navButton.setAttribute("aria-expanded", x);
        this.navElement.classList.toggle('show');
      })
    });
    const navLinkBrand = Array.from(this.document.querySelectorAll('.navLinkBrand'));
    navLinkBrand.forEach((l) => {
      l.addEventListener('click', () => {
        let x = this.navButton.getAttribute("aria-expanded");
        let y = this.navElement.getAttribute("aria-expanded");
        if (this.navElement.classList.contains('show')) {
          this.navButton.setAttribute("aria-expanded", "false");
          this.navElement.classList.toggle('show');
        }
      })
    });

  }

}
