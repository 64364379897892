import {
  Component,
  OnInit,
  Inject
} from "@angular/core";
import {
  DOCUMENT
} from '@angular/common';
import { ContentfulService } from '../../../services/contentful.service';
import { Entry } from 'contentful';
import { SendEmailServiceService } from '../../../services/send-email-service.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';



@Component({
  selector: "cta",
  templateUrl: "./cta.component.html",
  styleUrls: ["./cta.component.css"]
})
export class CtaComponent implements OnInit {
  bodyElement: HTMLElement;
  buttonElement: HTMLElement;
  ctaElement: HTMLElement;
  ctaWrap: HTMLElement;
  header: HTMLElement;
  close: HTMLElement;
  data!: Entry<any>;

  public subscription: Subscription;
  successMessage: any;
  errorMessage: any;

  public isCollapsed = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(DOCUMENT) private doc: Document,
    private contentfulService: ContentfulService,
    private sendmailservice: SendEmailServiceService,
    private fb: FormBuilder
  ) { }

  infoForm = this.fb.group({
    name: ['', [
      Validators.required,
      Validators.minLength(3)
    ]
    ],
    email: ['', [
      Validators.required,
      Validators.email
    ]
    ],
    phone: ['', [
      Validators.required
    ]
    ],
    message: ['', [
      Validators.required
    ]
    ]
  });

  get name() { return this.infoForm.get('name'); }
  get email() { return this.infoForm.get('email'); }
  get phone() { return this.infoForm.get('phone'); }
  get message() { return this.infoForm.get('message'); }

  sendMail(name, email, phone, message) {
    this.subscription = this.sendmailservice.sendEmail(this.infoForm.value).
      subscribe(data => {
        let msg = data['message']
        this.successMessage = true;
      }, error => {
        this.errorMessage = true;
      });
    email.reset();
    name.reset();
    phone.reset();
    message.reset();
  }

  ngOnInit(): void {
    const dataId = '78kMp1qW95cKiDsGVArdsa';
    const include = '5';
    this.contentfulService.getContact()
      .then((data) => {
        this.data = data;
      });

    this.bodyElement = null;
    this.ctaElement = null;
    this.buttonElement = null;
    this.ctaWrap = null;
    this.header = null;
    this.close = null;
  }
  ngAfterViewInit() {
    this.ctaElement = <HTMLElement>this.document.getElementById("line");
    this.buttonElement = <HTMLElement>this.document.getElementById("inlineCta");
    this.ctaWrap = <HTMLElement>this.document.getElementById("collapseExample");
    this.listen();
    this.addRecap('https://www.google.com/recaptcha/api.js');
    this.addScript();
  }

     public addRecap(url: string) {
    var elementExists = this.doc.getElementById("recapLinkC");
    if (elementExists) {
      elementExists.remove();
    }
    const body = <HTMLDivElement>this.doc.body;
    const script = this.doc.createElement('script');
    script.src = url;
    script.id = 'recapLinkC';
    body.appendChild(script);
  }

  public addScript() {
     var elementExists = this.doc.getElementById("recapScriptC");
      if (elementExists) {
        elementExists.remove();
      }
      const body = <HTMLDivElement>this.doc.body;
      const script = this.doc.createElement('script');
      script.innerHTML = 'function onSubmit(token) {document.getElementById("contact-form").submit();}'
      script.id = 'recapScripC';
      body.appendChild(script);
  }
  listen() {
    this.buttonElement.addEventListener('click', () => {
      let x = this.ctaWrap.getAttribute('aria-expanded');
      if (x == "true") {
        this.ctaElement.classList.add('fadeIn');
      } else {
        this.ctaElement.classList.remove('fadeIn');
      }
    });
  }
}
