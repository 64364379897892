import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';

import { ContentfulService } from '../../../services/contentful.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { CtaComponent } from './cta.component';


@NgModule({
  declarations: [
    CtaComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MarkdownModule.forRoot(),
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CtaComponent
  ],
  providers: [ContentfulService]
})
export class CtaModule { }
