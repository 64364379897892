  <section id="home1" class="full-height d-flex justify-content-start align-items-center flex-column position-relative">
    <div class="bg-image">
      <picture>
        <source media="(min-width: 900px)" srcset="{{data?.fields.bannerImage.fields.file.url}}?w=1440&fm=webp 1x, {{data?.fields.bannerImage.fields.file.url}}?fm=webp 2x" type="image/webp">
        <source media="(min-width: 601px)" srcset="{{data?.fields.bannerImage.fields.file.url}}?w=900&fm=webp 1x, {{data?.fields.bannerImage.fields.file.url}}?w=1440&fm=webp 2x" type="image/webp">
        <source media="(max-width: 600px)" srcset="{{data?.fields.bannerImage.fields.file.url}}?w=600&fm=webp 1x, {{data?.fields.bannerImage.fields.file.url}}?w=900&fm=webp 2x" type="image/webp">
        <img src="{{data?.fields.bannerImage.fields.file.url}}" alt="{{data?.fields.bannerImage.fields.title}}" class="img-fluid" />
      </picture>
    </div>

    <div class="bannerText text-center d-flex justify-content-start align-items-center flex-column">
      <h1 class="d-none d-md-block">{{data?.fields.bannerHeadline}}</h1>
      <markdown [data]="data?.fields.mobileBannerHeadline" class="d-block d-md-none" aria-hidden="true"></markdown>
      <hr class="mb-4" />
      <markdown [data]="data?.fields.bannerCopy"></markdown>
    </div>
  </section>
  <section id="one" class="p-8 bg-white">
    <div class="container pt-3 pt-lg-0 ">
      <div class="row d-flex justify-content-center justify-content-lg-start justify-content-xl-between flex-wrap">
        <div id="infoSection" class="d-flex justify-content-center mb-2 mb-lg-0">
          <div id="info" class="col-md-5 d-flex flex-column justify-content-center align-items-center">
            <h3 class="mb-3 minion-reg">{{data?.fields.servicesHeadline}}</h3>
            <div id="introWrap" class="d-flex flex-column">
              <markdown [data]="data?.fields.servicesCopy" class="text-center"></markdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="services" class="d-flex align-items-center justify-content-end flex-column bg-white p-8 pt-0">
    <div class="container p-0 container-md-fluid mb-5 ">
      <div class="row d-flex flex-wrap justify-content-center align-items-start g-0 w-100">
        <ng-container *ngFor="let service of data?.fields.services">
          <div class="col-6 col-lg-3 p-2 p-md-3 pt-0 mb-3 mb-lg-0 d-flex flex-column align-items-center justify-content-center">
            <a [routerLink]="service?.fields.link" routerLinkActive="active" [attr.aria-label]="'go to the ' + service?.fields.name + ' page'" class="serviceLink">
              <div class="serviceLabel d-flex justify-content-center align-items-center">
                Learn More
                <p class="visually-hidden">
                  About Pressberg Properties {{service?.fields.name}} services.
                  </p>
                </div>
            <picture>
              <source media="(min-width: 900px)" data-srcset="{{service?.fields.image.fields.file.url}}?w=353&h=335&fm=webp" type="image/webp" />
              <source media="(min-width: 411px)" data-srcset="{{service?.fields.image.fields.file.url}}?w=300&h=300&fm=webp" type="image/webp" />
              <source media="(min-width: 300px)" data-srcset="{{service?.fields.image.fields.file.url}}?w=250&h=250&fm=webp" type="image/webp" />
              <img src="{{service?.fields.image.fields.file.url}}" alt="{{service?.fields.image.fields.file.title}}?h=400&w=400" class="img-fluid" loading="lazy" />
            </picture>
            </a>
            <div class="text-wrap d-flex flex-column justify-content-center align-items-center text-center">
              <h4>{{service.fields.name}}</h4>
              <markdown [data]="service.fields.copy" class="text-center"></markdown>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <cta></cta>
  </section>
  <section class="lightgrey">
    <div class="container-fluid p-0">
      <div class="row d-flex flex-wrap m-0">
        <div class="col-md-6 p-0">
          <picture>
            <source media="(min-width: 900px)" data-srcset="{{data?.fields.section.fields.image.fields.file.url}}?fm=webp" type="image/webp" />
            <source media="(min-width: 411px)" data-srcset="{{data?.fields.section.fields.image.fields.file.url}}?w=450&fm=webp" type="image/webp" />
            <source media="(min-width: 300px)" data-srcset="{{data?.fields.section.fields.image.fields.file.url}}?w=400&fm=webp" type="image/webp" />
            <img data-src="{{data?.fields.section.fields.image.fields.file.url}}" alt="{{data?.fields.section.fields.image.fields.title}}" class="img-fluid" loading="lazy" />
          </picture>
        </div>
        <div class="col-md-6 p-0 d-flex align-items-top justify-content-center">
          <div class="sectionWrap">
            <markdown [data]="data?.fields.section.fields.headline"></markdown>
            <hr />
            <markdown [data]="data?.fields.section.fields.copy"></markdown>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="clients" class="d-flex align-items-center justify-content-end flex-column bg-white p-8 pt-5 pb-5">
    <div class="container mb-5 text-center mt-5 ">
      <h3 class="inlineBox">{{data?.fields.previousClientSectionHeadline}}</h3>
      <div class="col-md-12 worked border-black">
        <ng-container *ngFor="let client of data?.fields.previousClients">
          <p>
            {{client?.fields.name}}
            </p>
        </ng-container>
      </div>


    </div>
  </section>
