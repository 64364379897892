import { Component, OnInit } from '@angular/core';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { ContentfulService } from '../../../services/contentful.service';
import { Entry } from 'contentful';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  features: any;
  data!: Entry<any>;
  l = false;

  constructor(
    private contentfulService: ContentfulService,
    config: NgbCarouselConfig,
    private titleService: Title,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    config.showNavigationIndicators = false;
    config.showNavigationArrows = false;
    config.interval = 6000;
  }
  _returnHtmlFromRichText(richText: any) {
    if (richText === undefined || richText === null || richText.nodeType !== 'document') {
      return '<p>Error</p>';
    }
    return documentToHtmlString(richText);
  }

  ngOnInit(): void {
    this.l = false;
    const pageId = '7B9EJpbkXtIJ6hVaALJDku';
    const include = '5';
    this.contentfulService.getHomePage(pageId, include)
      .then((data) => {
        this.data = data;
        this.l = true;
        this.titleService.setTitle(data.fields.seoTitle);
        this.meta.updateTag({ id: "siteDescription", name: "description", content: data.fields.seoDescription });
        this.meta.updateTag({ property: 'og:title', content: data.fields.seoTitle });
        this.meta.updateTag({ property: 'og:description', content: data.fields.seoDescription });
        this.meta.updateTag({ property: 'og:image', content: "/pressberg-properties-icon.png" });
        this.meta.updateTag({ property: 'og:site_name', content: data.fields.seoTitle });
        this.meta.updateTag({ property: 'og:url', content: "https://www.pressbergproperties.com/" });

        this.meta.updateTag({ property: 'twitter:title', content: data.fields.seoTitle });
        this.meta.updateTag({ property: 'twitter:description', content: data.fields.seoDescription });
        this.meta.updateTag({ property: 'twitter:image', content: "/pressberg-properties-icon.png" });
        this.meta.updateTag({ property: 'twitter:url', content: "https://www.pressbergproperties.com/" });
      });
    setTimeout(() => { }, 500);
  }


  ngAfterViewInit() {}


}
