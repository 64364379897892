import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/general/home/home.component';
import { NotFoundComponent } from './modules/general/not-found/not-found.component';

const routes: Routes = [
  { path: '', component: HomeComponent, },
  {
    path: 'about',
    loadChildren: () => import('./modules/general/about/about.module')
      .then(mod => mod.AboutModule)
  },
  {
    path: 'commercial',
    loadChildren: () => import('./modules/general/commercial/commercial.module')
      .then(mod => mod.CommercialModule)
  },
  {
    path: 'residential',
    loadChildren: () => import('./modules/general/residential/residential.module')
      .then(mod => mod.ResidentialModule)
  },
  {
    path: 'management',
    loadChildren: () => import('./modules/general/management/management.module')
      .then(mod => mod.ManagementModule)
  },
  { path: 'wholesale', loadChildren: () => import('./modules/general/wholesale/wholesale.module').then(m => m.WholesaleModule) },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    relativeLinkResolution: 'legacy',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
